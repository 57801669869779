import './App.css';
import { Route, Routes } from "react-router-dom";
import { Home } from './components/Home/Home';
import { Login } from './components/Login/Login';
import { Courses } from './components/Courses/Courses';
import { PrimeResearch } from './components/PrimeResearch/PrimeResearch';
import { Navbar } from './components/Navbar';
import Bucket from './components/Buckets/Bucket';
import Companies from './components/Companies/Companies';
import CompanyDetails from './components/Companies/CompanyDetails';
import BucketDetails from './components/Buckets/BucketDetails';
import CourseDetails from './components/Courses/CourseDetails';
import CreateCourse from './components/Courses/CreateCourse';
import CreateCompany from './components/Companies/CreateCompany';
import News from './components/News/News';
import AddNews from './components/News/AddNews';
import { ProtectedRoute } from './components/ProtectedRoute';
import Users from './components/Users/Users';
import CreateBucket from './components/Buckets/CreateBucket';
import UnProtectedRoute from './components/UnProtectedRoute';
import { UserDetails } from './components/Users/UserDetails';
import PrimeOrders from './components/PrimeResearch/PrimeOrders';
import EnrollUser from './components/Courses/enrollUser';
import CreateOrder from './components/PrimeResearch/CreateOrder';
import Revenue from './components/Analytics/Revenue';
import AnalysePrime from './components/Analytics/AnalysePrime';
import TrialBatch from './components/TrialAccess/TrialBatch';
import TrialUsers from './components/TrialAccess/TrialUsers';
import AddBucketData from './components/Companies/AddBucketData';
import SendMail from './components/SendMail/SendMail';
import Pulse from './components/Pulse/Pulse';
import Referrals from './components/Referrals/Referrals';
import ParentBuckets from './components/NestedBuckets/ParentBuckets';
import ParentBucketDetails from './components/NestedBuckets/ParentBucketDetails';
import CreateChildBucket from './components/NestedBuckets/CreateChildBucket';
import ChildBucketDetails from './components/NestedBuckets/ChildBucketDetails';
import SendAppNotification from './components/SendMail/SendAppNotification';
import BatchUsersList from './components/FamilyTimesBatches/BatchUsersList';
import HotLeads from './components/HotLeads/HotLeads';
import TrialUserAnalysis from './components/TrialUserAnalysis/TrialUserAnalysis';
import UserCategorization from './components/UserCategorization/UserCategorization';
import TimesBatches from './components/FamilyTimesBatches/Batches';
import FamilyBatchUsersList from './components/FamilyTimesBatches/FamilyBatchUsersList copy';
import EditorComponent from './components/Editor/EditorComponent';
import PrimeArticleView from './components/PrimeResearch/PrimeArticleView';
import IPOs from './components/IPO/Ipos';
import IpoArticleView from './components/IPO/IpoArticleView';
import AddPrimeArticle from './components/PrimeResearch/AddPrimeArticle';
import AddIpoArticle from './components/IPO/addIpoArticle';
import AddDiscoveryData from './components/Companies/AddDiscoveryData';
import AddNewsByBlocks from './components/News/AddNewsByBlocks';
import VerifyCompaniesData from './components/Companies/Verify';
import AccessExpiry from './components/AccessExpiry/AccessExpiry';
import Questions from './components/Questions/Questions';
import AddQuestion from './components/Questions/AddQuestion';
import SearchHistory from './components/SearchHistory/SearchHistory';

function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        <Route path='/login' element={
          // <UnProtectedRoute>
            <Login />
          // </UnProtectedRoute>
        } />

        <Route path="/" element={
          <ProtectedRoute >
            <Home />
          </ProtectedRoute>
        } />

        <Route path='/courses' element={
          <ProtectedRoute>
            <Courses />
          </ProtectedRoute>
        } />

        <Route path='/courses/:id' element={
          <ProtectedRoute>
            <CourseDetails />
          </ProtectedRoute>
        } />

        <Route path='/courses/:id/enroll_user' element={
          <ProtectedRoute>
            <EnrollUser />
          </ProtectedRoute>
        } />

        <Route path='/courses/create' element={
          <ProtectedRoute>
            <CreateCourse />
          </ProtectedRoute>
        } />

        <Route path='/prime' element={
          <ProtectedRoute>
            <PrimeResearch />
          </ProtectedRoute>
        } />

        <Route path='/prime/add' element={
          <ProtectedRoute>
            <AddPrimeArticle />
          </ProtectedRoute>
        } />

        <Route path='/prime/:slug' element={
          <ProtectedRoute>
            <PrimeArticleView />
          </ProtectedRoute>
        } />

        <Route path='/ipos' element={
          <ProtectedRoute>
            <IPOs />
          </ProtectedRoute>
        } />

        <Route path='/ipos/add' element={
          <ProtectedRoute>
            <AddIpoArticle />
          </ProtectedRoute>
        } />

        <Route path='/ipos/:slug' element={
          <ProtectedRoute>
            <IpoArticleView />
          </ProtectedRoute>
        } />

        <Route path='/prime/orders' element={
          <ProtectedRoute>
            <PrimeOrders />
          </ProtectedRoute>
        } />

        <Route path='/prime/orders/create-order' element={
          <ProtectedRoute>
            <CreateOrder />
          </ProtectedRoute>
        } />

        <Route path='/buckets' element={
          <ProtectedRoute>
            <Bucket />
          </ProtectedRoute>
        } />

        <Route path='/buckets/create' element={
          <ProtectedRoute>
            <CreateBucket />
          </ProtectedRoute>
        } />

        <Route path='/buckets/:title' element={
          <ProtectedRoute>
            <BucketDetails />
          </ProtectedRoute>
        } />

        <Route path='/parent-buckets' element={
          <ProtectedRoute>
            <ParentBuckets />
          </ProtectedRoute>
        } />

        <Route path='/parent-buckets/:title' element={
          <ProtectedRoute>
            <ParentBucketDetails />
          </ProtectedRoute>
        } />

        <Route path='/child-buckets/add' element={
          <ProtectedRoute>
            <CreateChildBucket />
          </ProtectedRoute>
        } />

        <Route path='/parent-buckets/:title/:child' element={
          <ProtectedRoute>
            <ChildBucketDetails />
          </ProtectedRoute>
        } />

        <Route path='/companies' element={
          <ProtectedRoute>
            <Companies />
          </ProtectedRoute>
        } />

        <Route path='/companies/search-history' element={
          <ProtectedRoute>
            <SearchHistory />
          </ProtectedRoute>
        } />

        <Route path='/companies/verify' element={
          <ProtectedRoute>
            <VerifyCompaniesData />
          </ProtectedRoute>
        } />

        <Route path='/companies/:id' element={
          <ProtectedRoute>
            <CompanyDetails />
          </ProtectedRoute>
        } />

        <Route path='/companies/:id/update-bucket-data' element={
          <ProtectedRoute>
            <AddBucketData />
          </ProtectedRoute>
        } />

        <Route path='/companies/:id/update-discovery-data' element={
          <ProtectedRoute>
            <AddDiscoveryData />
          </ProtectedRoute>
        } />

        <Route path='/companies/create' element={
          <ProtectedRoute>
            <CreateCompany />
          </ProtectedRoute>
        } />

        <Route path="/news" element={
          <ProtectedRoute>
            <News />
          </ProtectedRoute>
        } />

        <Route path="/news/add" element={
          <ProtectedRoute>
            <AddNews />
          </ProtectedRoute>
        } />

        <Route path="/news/new" element={
          <ProtectedRoute>
            <AddNewsByBlocks />
          </ProtectedRoute>
        } />

        <Route path="/users" element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        } />

        <Route path="/users/:id" element={
          <ProtectedRoute>
            <UserDetails />
          </ProtectedRoute>
        } />

        <Route path="/revenue" element={
          <ProtectedRoute>
            <Revenue />
          </ProtectedRoute>
        } />

        <Route path="/analytics/prime" element={
          <ProtectedRoute>
            <AnalysePrime />
          </ProtectedRoute>
        } />

        <Route path="/users/trial" element={
          <ProtectedRoute>
            <TrialBatch />
          </ProtectedRoute>
        } />

        <Route path="/users/trial/list" element={
          <ProtectedRoute>
            <TrialUsers />
          </ProtectedRoute>
        } />

        <Route path="/send-mail" element={
          <ProtectedRoute>
            <SendMail />
          </ProtectedRoute>
        } />

        <Route path="/send-app-notification" element={
          <ProtectedRoute>
            <SendAppNotification />
          </ProtectedRoute>
        } />

        {/* Macro Articles Component*/}
        <Route path="/pulse" element={
          <ProtectedRoute>
            <Pulse />
          </ProtectedRoute>
        } />

        <Route path="/referrals" element={
          <ProtectedRoute>
            <Referrals />
          </ProtectedRoute>
        } />


        <Route path="/times-batches" element={
          <ProtectedRoute>
            <TimesBatches />
          </ProtectedRoute>
        } />

        <Route path="/family-batches" element={
          <ProtectedRoute>
            <FamilyBatchUsersList />
          </ProtectedRoute>
        } />

        <Route path="/times-batches/list" element={
          <ProtectedRoute>
            <BatchUsersList />
          </ProtectedRoute>
        } />

        <Route path="/hot-leads" element={
          <ProtectedRoute>
            <HotLeads />
          </ProtectedRoute>
        } />

        <Route path="/access-expiry" element={
          <ProtectedRoute>
            <AccessExpiry />
          </ProtectedRoute>
        } />

        <Route path="/trial-user-analysis" element={
          <ProtectedRoute>
            <TrialUserAnalysis />
          </ProtectedRoute>
        } />

        <Route path="/user-categorization" element={
          <ProtectedRoute>
            <UserCategorization />
          </ProtectedRoute>
        } />

        <Route path="/questions" element={
          <ProtectedRoute>
            <Questions />
          </ProtectedRoute>
        } />

        <Route path="/questions/add" element={
          <ProtectedRoute>
            <AddQuestion />
          </ProtectedRoute>
        } />

        <Route path="/editor" element={
          <ProtectedRoute>
            <EditorComponent />
          </ProtectedRoute>
        } />

      </Routes>
    </div>
  );
}

export default App;
