import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Referrals.module.css";
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

const Referrals = () => {
    const [referrals, setReferrals] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: 50,
        total_pages: 1,
        total_documents: 0,
    });
    const [filter, setFilter] = useState({ status: 'All' });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [pagination.page, filter.status]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.sovrenn.com/referrals/data?page=${pagination.page}&page_size=${pagination.page_size}&status=${filter.status}`, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user")
                }
            });
            const data = await response.json();
            setReferrals(data.data);
            setPagination({
                ...pagination,
                total_pages: data.pagination.total_pages,
                total_documents: data.pagination.total_documents,
            });
        } catch (error) {
            console.error('Error fetching referrals data', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (event, value) => {
        setPagination({ ...pagination, page: value });
    };

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h2>Referrals</h2>
            </div>

            {/* Filter Dropdown */}
            <select onChange={(e) => setFilter({ ...filter, status: e.target.value })} value={filter.status}
                style={{
                    padding: '5px',
                    fontSize: '14px',
                    borderRadius: '5px',
                    borderColor: '#ccc',
                    width: '200px',
                }}>
                <option value="All">All</option>
                <option value="No Action">No Action</option>
                <option value="Account Created">Account Created</option>
                <option value="Trial Active">Trial Active</option>
                <option value="Trial Expired">Trial Expired</option>
                <option value="Claim Now">Bought Full Access</option>
                {/* <option value="Claimed">Claimed</option> */}
                <option value="reward_claim_request">Reward Claim Request</option>
                <option value="reward_processed">Reward Processed</option>
            </select>

            {/* Loader */}
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "100px auto" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 'bold' }}>Referred User Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Referrer Name</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Referred Phone Number</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Reward Claim Request</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Reward Processed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {referrals.map((referral) => (
                            <TableRow key={referral._id}>
                                <TableCell>{referral.referred_user_name}</TableCell>
                                <TableCell>{referral.current_status.text}</TableCell>
                                <TableCell>
                                    <Link style={{ textDecoration: "none" }} to={`/users/${referral.referrer_id}`} target="_blank">
                                        {referral.referrer_name}
                                    </Link>
                                </TableCell>
                                <TableCell>{moment(referral.createdAt).format("Do MMM YYYY")}</TableCell>
                                <TableCell>{referral.phone_number}</TableCell>
                                <TableCell>{referral.reward_claim_request ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{referral.reward_processed ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}

            {/* Pagination */}
            <Pagination
                count={pagination.total_pages}
                page={pagination.page}
                onChange={handlePageChange}
                color="primary"
                sx={{ marginTop: "20px" }}
            />
        </div>
    );
};

export default Referrals;