import { Add, Delete, Edit, UploadFile } from '@mui/icons-material';
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getCompaniesList, getCompaniesListFun, getFilteredCompanies, isLoading } from '../../Redux/company/action';
import commonStyles from "../../styles/Common.module.css";
import styles from "./styles/Companies.module.css";
import tableStyles from "../Users/styles/Users.module.css";
import modalStyles from "../../styles/ModalStyle.module.css";
import { getBucketsFun } from '../../Redux/bucket/action';
import { getSectorsDataFun } from '../../Redux/sector/action';

const Companies = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { companies_list, is_loading } = useSelector((store) => store.companies);
    const { buckets } = useSelector((store) => store.bucket);
    const { sectors } = useSelector((store) => store.sectors);

    const [filterValue, setFilterValue] = useState("");
    const [companyData, setCompanyData] = useState({
        company: "",
        remark: "",
        date: "",
        bucketId: "",
    });

    const [progress, setProgress] = useState(false);
    const bhaavCopyRef = useRef();
    const bhaavCopyExchangeRef = useRef();
    const [dialog, setDialog] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [companyUpdateData, setCompanyUpdateData] = useState({
        no_of_shares: 0,
        ttm_pat: 0,
        isin: "",
        id: "",
        data_of: "",
        sector_id: "",
    });


    const content = searchParams.get("content");
    const quarter = searchParams.get("quarter");

    const bucketList = buckets?.map((ele) => {
        return { label: ele.title, id: ele._id };
    });

    useEffect(() => {
        dispatch(getBucketsFun());
        if (content) {
            dispatch(getFilteredCompanies(content, quarter));
            return;
        }
        dispatch(getSectorsDataFun());

        dispatch(getCompaniesListFun(is_loading));

        return () => {

        }
    }, [content, quarter]);


    useEffect(() => {
        if (companyUpdateData.id) {
            const updateData = setTimeout(() => {
                sendUpdateRequest();

            }, 1000);

            return () => clearTimeout(updateData);
        }

        return () => {

        }
    }, [companyUpdateData]);

    const filterChange = (ele) => {

        dispatch(isLoading(true));

        const { value } = ele.target;

        setFilterValue(value);
        let split = value.split(":");

        if (split[0] === "quarter") {
            let filterBy = split[0];
            let filter = split[1];
            navigate(`/companies?content=${filterBy}&quarter=${filter}`);

            dispatch(isLoading(false))
            return;
        }
        else {
            navigate(`/companies?content=${value}`);

            dispatch(isLoading(false))
            return;
        };
    };

    const addToBucket = async (event) => {
        event.preventDefault();

        setModalOpen(false);
        if (!companyData.company.length || !companyData.remark.length) return;


        const res = await fetch(`https://api.sovrenn.com/buckets/${companyData.bucketId}`, {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: JSON.stringify({
                company: companyData.company,
                remark: companyData.remark,
                date: companyData.date
            })
        });

        const data = await res.json();

        if (content) {
            dispatch(getFilteredCompanies(content));
            return;
        }

        dispatch(getCompaniesListFun(false));
        return;
    };


    const updateCompany = (ele, objectId) => {
        const { id, value } = ele.target;

        const updatedData = companies_list.map((item) =>
            item._id === objectId && id ? { ...item, [id]: value } : item
        );

        setCompanyUpdateData({
            ...companyUpdateData,
            id: objectId,
            [id]: value
        });

        dispatch(getCompaniesList(updatedData));
    };

    const sendUpdateRequest = async () => {
        if (!companyUpdateData.id) return;

        const body = {};

        for (let key in companyUpdateData) {
            if (key !== "id" && companyUpdateData[key]) {
                body[key] = companyUpdateData[key];
            };
        };

        const data = await fetch(`https://api.sovrenn.com/company/${companyUpdateData.id}`, {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user"),
                "Content-type": "application/json"
            },
            body: JSON.stringify(body)
        })
            .then((res) => res.json());

        if (data.success) {
            console.log("Company edited successfully!")
        }
        else {
            console.log("Ooops something went wrong please try again");
        };

        if (content) {
            dispatch(getFilteredCompanies(content));
            return;
        }

        dispatch(getCompaniesListFun(false));
        return;
    };

    const hideUnhideCompany = async (companyId, hideState) => {

        if (!companyId) return;

        const data = await fetch(`https://api.sovrenn.com/company/hide-company/${companyId}?hidestate=${hideState}`, {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            }
        })
            .then((res) => res.json());

        if (data.success) {
            console.log("Company edited successfully!")
        }
        else {
            console.log("Ooops something went wrong please try again");
        };

        if (content) {
            dispatch(getFilteredCompanies(content));
            return;
        }

        dispatch(getCompaniesListFun(false));
        return;
    };

    const updateByBhaavCopy = async (event) => {
        if (!bhaavCopyExchangeRef.current.value || !bhaavCopyRef.current.files[0]) {
            return;
        };

        setDialog(false);
        setProgress(true);

        const formData = new FormData();

        formData.set("bhaav_copy_csv", bhaavCopyRef.current.files[0]);
        formData.append("exchange", bhaavCopyExchangeRef.current.value);

        const res = await fetch("https://api.sovrenn.com/company/bhaav-copy/daily", {
            method: "PATCH",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("sov_user")
            },
            body: formData
        });

        const data = await res.json();

        // console.log(data);

        setProgress(false);

        if (res.ok) {
            alert(`Data of ${data.results.length} companies updated successfully.`)
            return;
        }

        alert("Something went wrong.");
        return;
    };

    if (is_loading) {
        return (
            <div className={commonStyles.MainContainer}>

                <div className={commonStyles.headerDiv}>
                    <h1>Companies</h1>
                    <div >
                        <Button color="secondary" variant="outlined" fullWidth startIcon={<UploadFile />} sx={{ width: "150px" }} onClick={() => {
                            setDialog(true);
                        }}>Bhaav Copy</Button>

                        <Link to="/companies/search-history">
                            <Button color="secondary" variant="outlined" fullWidth sx={{ width: "140px" }}>Search Data</Button>
                        </Link>

                        <Link to="/companies/verify">
                            <Button color="secondary" variant="outlined" fullWidth sx={{ width: "140px" }}>Verify Data</Button>
                        </Link>

                        <Link to="/companies/create">
                            <Button color="secondary" variant="outlined" startIcon={<Add />} fullWidth sx={{ width: "210px" }}>Add New Company</Button>
                        </Link>
                    </div>
                </div>
                <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "100px auto" }}>
                    <CircularProgress />
                </Box>
            </div>
        )
    };

    return (
        <div className={commonStyles.MainContainer}>

            <div className={commonStyles.headerDiv}>
                <h1>Companies</h1>

                <div >
                    <Button color="secondary" variant="outlined" fullWidth startIcon={<UploadFile />} sx={{ width: "160px" }} onClick={() => {
                        setDialog(true);
                    }}>Bhaav Copy</Button>

                    <Link to="/companies/search-history">
                        <Button color="secondary" variant="outlined" fullWidth sx={{ width: "140px" }}>Search Data</Button>
                    </Link>

                    <Link to="/companies/verify">
                        <Button color="secondary" variant="outlined" fullWidth sx={{ width: "140px" }}>Verify Data</Button>
                    </Link>

                    <Link to="/companies/create">
                        <Button color="secondary" variant="outlined" startIcon={<Add />} fullWidth sx={{ width: "210px" }}>Add New Company</Button>
                    </Link>
                </div>
            </div>

            <div id={styles.filterContainer}>
                <div className={styles.inputDiv}>
                    <label htmlFor="filter-by-content">Filter</label>
                    <select value={filterValue} id='filter-by-content' onChange={filterChange}>
                        <option value="">Choose</option>
                        <option value="no-content">IPO Only</option>
                        <option value="SME">SME Companies</option>
                        <option value="No SME">Non SME Companies</option>
                        <option value="news">News Content</option>
                        <option value="bucket_doc">Proper Content</option>
                        <option value="quarter:March 23">March 23</option>
                        <option value="quarter:June 23">June 23</option>
                        <option value="quarter:Sep 23">Sep 23</option>
                        <option value="quarter:Dec 23">Dec 23</option>
                        <option value="quarter:March 24">March 24</option>
                        <option value="quarter:June 24">June 24</option>
                    </select>
                </div>
            </div>

            <table id={tableStyles.userListContainer}>
                <thead id={tableStyles.userListHead}>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Company Name</th>
                        <th>Sector</th>
                        {/* <th>ISIN</th> */}
                        <th>Market Cap</th>
                        {/*<th>TTM PE</th>
                        <th>Revenue Growth</th>*/}
                        <th>TTM PAT</th>
                        <th>Type</th>
                        <th>Data Of</th>
                        <th>Buckets Present</th>
                        {/* <th>Content</th> */}
                        <th>Hide Company</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {companies_list.map((ele, ind) => {
                        return <tr className={tableStyles.userCard} key={ind}>
                            <td>{ind + 1}</td>
                            <td className={styles.name}>{ele.company_name}</td>

                            <td style={{ width: "90px" }}>
                                <select type="text" value={ele?.sector_id?._id} onChange={(item) => {
                                    updateCompany(item, ele._id)
                                }} id="sector_id" className={styles.thInput} >
                                    <option value="">NA</option>

                                    {sectors.map((ele, ind) => {
                                        return <option key={ele._id} value={ele._id}>{ele.name}</option>
                                    })}
                                </select>
                            </td>

                            <td style={{ width: "70px" }}><input type="number" value={ele.market_cap} onChange={(item) => {
                                updateCompany(item, ele._id)
                            }} id="market_cap" className={styles.thInput} /></td>

                            <td style={{ width: "60px" }}><input type="number" value={ele.ttm_pat} onChange={(item) => {
                                updateCompany(item, ele._id)
                            }} id="ttm_pat" className={styles.thInput} /></td>

                            <td style={{ width: "90px" }}>
                                <select type="text" value={ele.company_type} onChange={(item) => {
                                    updateCompany(item, ele._id)
                                }} id="company_type" className={styles.thInput} >
                                    <option value=""></option>
                                    <option value="SME">SME</option>
                                    <option value="Non SME">Non SME</option>
                                </select>
                            </td>

                            <td style={{ width: "100px" }}>
                                <select type="text" value={ele.data_of} onChange={(item) => {
                                    updateCompany(item, ele._id)
                                }} id="data_of" className={styles.thInput} >
                                    <option value=""></option>
                                    <option value="March 23">March 23</option>
                                    <option value="June 23">June 23</option>
                                    <option value="Sep 23">Sep 23</option>
                                    <option value="Dec 23">Dec 23</option>
                                    <option value="March 24">March 24</option>
                                    <option value="June 24">June 24</option>
                                </select>
                            </td>

                            <td className={styles.buckets}>{ele.baskets.map((item, posi) => {
                                return <p key={posi}><Link to={`/buckets/${item}`}>
                                    {item}</Link></p>
                            })}</td>

                            {/* <td style={{
                                color: (ele?.discovery_content && ele.baskets.length ? "green" : ele.news ? "orange" : "red"),

                                fontWeight: "bold"
                            }}>{
                                    ele?.discovery_content && ele.baskets.length ? "YES" : ele.news ? "NEWS" : "NO"
                                }</td> */}

                            <td>
                                <Button onClick={() => {
                                    hideUnhideCompany(ele._id, ele?.has_hidden ? !ele.has_hidden : true)
                                }} style={{ fontStyle: "bold" }} color={ele?.has_hidden ? "error" : "secondary"} className={styles.editButton}>
                                    {ele?.has_hidden ? "Unhide" : "Hide"}
                                </Button>
                            </td>
                            <td>
                                <Link to={`/companies/${ele._id}`}>
                                    <IconButton className={styles.editButton}>
                                        <Edit />
                                    </IconButton>
                                </Link>
                            </td>
                            <td>
                                <IconButton className={styles.editButton} onClick={() => {
                                    setCompanyData({
                                        ...companyData,
                                        company: ele._id
                                    });

                                    setModalOpen(true);
                                }}>
                                    <Add />
                                </IconButton>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>


            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Box className={modalStyles.InfoModal}>
                    <h3>Select Bucket</h3>

                    <Autocomplete
                        sx={{ margin: "20px 0" }}
                        fullWidth
                        size="medium"
                        disablePortal
                        id="company_Id"
                        name="company_Id"
                        options={bucketList}
                        onChange={(ele, value) => {
                            setCompanyData({
                                ...companyData,
                                bucketId: value.id
                            })
                        }}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderInput={(params) => <TextField required {...params} label="Bucket" />}
                    />

                    <TextField
                        id="outlined-multiline-static"
                        label="Remark"
                        multiline
                        rows={4}
                        fullWidth
                        sx={{ fontSize: "small" }}
                        required
                        onChange={(ele) => {
                            setCompanyData({
                                ...companyData,
                                remark: ele.target.value
                            })
                        }}
                    />

                    <input type="date" style={{ width: "100%", height: "40px", padding: "10px", fontSize: "large", margin: "20px 0" }} required onChange={(ele) => {
                        setCompanyData({
                            ...companyData,
                            date: ele.target.value
                        })
                    }} />

                    <button className={modalStyles.submitBtn} onClick={addToBucket}>Add</button>
                </Box>
            </Modal>

            <Dialog open={dialog} onClose={() => setDialog(false)}>
                <DialogTitle sx={{ fontWeight: "bolder" }}>Select File (.csv)</DialogTitle>
                <DialogContent >
                    <input type="file" ref={bhaavCopyRef} accept="text/csv" id={styles.fileInput} />
                    <FormControl sx={{ marginTop: "20px" }} size='small' required fullWidth>
                        <InputLabel id="demo-simple-select-label">Exchange</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Exchange"
                            inputRef={bhaavCopyExchangeRef}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={"BSE"}>BSE</MenuItem>
                            <MenuItem value={"NSE"}>NSE</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={() => {
                        setDialog(false);
                    }}>Cancel</Button>
                    <Button onClick={updateByBhaavCopy}>Upload</Button>
                </DialogActions>
            </Dialog>

            {progress ?
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: "column",
                    gap: "20px",
                    zIndex: 9999,

                }}>
                    <CircularProgress style={{ width: "80px", height: "80px" }} color="success" />

                    <h4 style={{ backgroundColor: "#fff6ea", padding: "10px" }}>It may take some time to upload, please do not close the window.</h4>
                </Box>
                :
                ""
            }
        </div>
    )
}

export default Companies