import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CircularProgress, Table, TableHead, TableBody, TableCell, TableRow, Pagination, Box } from "@mui/material";
import commonStyles from "../../styles/Common.module.css";

const SearchHistory = () => {
    const [searchHistory, setSearchHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const pageSize = 50;

    const fetchSearchHistory = async (page) => {
        setIsLoading(true);
        try {
            const response = await fetch(`https://api.sovrenn.com/search-history/data?page=${page}&page_size=${pageSize}`, {
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("sov_user")
                }
            });
            const result = await response.json();

            if (result.success) {
                setSearchHistory(result.data);
                setTotalPages(result.pagination.total_pages);
            }
        } catch (error) {
            console.error("Error fetching search history:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSearchHistory(page);
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className={commonStyles.MainContainer}>
            <div className={commonStyles.headerDiv}>
                <h2>Last 30 Days Search History</h2>
            </div>

            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: "center", width: 100, margin: "100px auto" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold' }}>Company Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold' }}>Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchHistory.map((elem, ind) => (
                                <TableRow key={ind + 1}>
                                    <TableCell>{elem.company_name}</TableCell>
                                    <TableCell>{elem.count}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* 

                        {/* Pagination Component */}
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
        </div>
    );
};

export default SearchHistory;